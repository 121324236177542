.contract {
    text-align: center;
    color: black;
}

.clientContract {
}

@media screen and (max-width: 1500px) {

    .clientContract {
        width:100%
    }
}