.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.LogList h2 {
  margin-top: 2%;
  margin-bottom: 2%;
}

.LogList {
  text-align: center;
}

.ClientList {
  width: 33%;
  margin-left: 33%;
  text-align: center;
}

.enrollButton {
  margin-top: 9%;
  text-align: center;
}

.Legend {
  width: 30%;
  margin-left: 10%;
  text-align: center;
  position: fixed;
  right: 0px;
  bottom: 0px;
  padding: 5px;
}

.Legend h2 {
  text-align: left;
}

.BtnAuth button {
  margin-left: 1%;
}

.Client {
  margin-top: 2%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

div .colored-circle {
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: -2px;
  border-radius: 50%;
  border-style: solid;
  border-width: 0.1px;
  border-color: #bfbdbd;
  height: 20px;
  width: 20px;
}

.MuiFormControlLabel-label {
  color: rgba(0, 0, 0, 0.6);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  transform: scale(0.75);
  transform-origin: left;
}

.MuiFormControl-root {
  font-family: 'Barlow' !important;
  font-size: small !important;
}

.MuiInputLabel-root,
.MuiFormControl-root label,
.MuiInputBase-root {
  font-family: 'Barlow' !important;
  font-size: smaller !important;
}

.MuiDataGrid-cellContent,
.MuiDataGrid-cell {
  font-size: x-small;
}

.MuiInputBase-root fieldset legend {
  padding: 0 !important;
  margin: 0 !important;
  display: none;
}

.MuiOutlinedInput-notchedOutline {
  top: 0 !important;
}

.MuiMenuItem-root {
  font-family: 'Barlow' !important;
  font-size: x-small !important;
}

li.MuiMenuItem-root .MuiButtonBase-root {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

input.MuiInputBase-input {
  background-color: #fff;
}

.MuiInputLabel-root {
  overflow: visible !important;
  text-overflow: inherit !important;
}

.MuiInputLabel-formControl {
  text-transform: capitalize;
  color: black !important;
  position: relative !important;
  transform: none !important;
  white-space: nowrap;
  background-color: #f0f0f0;
  border-top: 1px solid rgba(0, 0, 0, 0.26);
  border-bottom: 1px solid rgba(0, 0, 0, 0.26);
  border-left: 1px solid rgba(0, 0, 0, 0.26);
}

.MuiFormControl-root {
  width: 100% !important;
}

.MuiFormControl-root {
  flex-direction: row !important;
}

.MuiTextField-root {
  width: 100% !important;
}

.MuiOutlinedInput-input {
  padding: 0 !important;
}

.MuiInputLabel-formControl {
  padding: 3px !important;
}

.MuiInputBase-root {
  padding-left: 5px;
  flex-grow: 2;
}

.PrivateNotchedOutline-root-2 {
  border: none;
  top: 0px !important;
}

.MuiBox-root .MuiFormControl-root {
  margin: 0 !important;
  padding: 3px !important;
}

.MuiOutlinedInput-notchedOutline {
  border: none;
}

.MuiOutlinedInput-root {
  border-radius: 0 !important;
}

li.MuiButtonBase-root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

div.react-swipeable-view-container,
.react-swipeable-view-container div.MuiBox-root p.MuiTypography-root {
  height: 100%;
}

.content {
  position: absolute;
  top: 80px;
  left: 0;
  margin: -80px 0 0 0;
  padding: 80px 5px 0 5px;
  height: 100%;
  width: 100%;
  /* overflow: hidden; */
}

/*title*/
@font-face {
  font-family: backToSchool;
  src: url('./components/backToSchoolFont/backtoschool-webfont.woff');
}

/*sub title*/
@font-face {
  font-family: overlockBold;
  src: url('./components/OverCock/overlock-bold-webfont.woff');
}

@font-face {
  font-family: overlockBlack;
  src: url('./components/OverCock/overlock-black-webfont.woff');
}

@font-face {
  font-family: overlockRegular;
  src: url('./components/OverCock/overlock-regular-webfont.woff');
}