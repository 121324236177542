ul.list-settings {
    list-style-type: none;
    max-width: 50%;
    margin: 10px auto;
    padding: 0;
}

ul.list-settings li {
    max-width: 50%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 0 5px;
    background-color: #fff;
    border-bottom: 1px solid #e0e0e0;
}

/*
ul.list-settings li:nth-child(odd) {
}
*/

ul.list-settings li:not(:last-child):hover {
    background-color: #f0f0f0;
}

ul.list-settings li:last-child {
    margin-top: 5px;
}
