/* .presence {
    text-align: center;
    color: black;
}

.Research,
.research {
    text-align: center;
    color: black;
} */

.presenceItemPlacement {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    flex-wrap: nowrap;
}

@media screen and (max-width: 1050px) {
    .presenceItemPlacement {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        flex-wrap: wrap;
    }
}

/* .researchBox h3 {
    font-weight: bold;
}

.researchBox {
    width: 640px;
    margin: 2% 0 2% 0;
    padding: 2% 2% 2% 2%;
    text-align: center;
    border-style: solid;
    height: 140px;
}

.presenceButton {
    margin: 0;
    padding: 0;
}

.presenceGrid {
    height: 100%;
    width: 100%;
    display: inline-block;
}

.researchGrid {
    width: 640px;
    display: inline-block;
}

@media screen and (max-width: 1300px) {
    .presenceGrid {
        width: 100%;
    }

    .researchGrid {
        width: 100%;
    }

    .researchBox {
        width: 100%;
        margin-bottom: 2%;
        margin-top: 2%;
        text-align: center;
    }
} */