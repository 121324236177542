.userInfo{
    background-color: #72caed;
    text-align: center;
    color: #111010;
}

.user-grid-container {
    text-align: center;
}

.user-data-grid {
    width : 1100px;
    display: inline-flex;
}