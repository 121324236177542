.clientInformation h4 {
    text-align: center;
    /* color: white; */
    font-weight: bolder;
}

/* .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled {
    background-color: #fff;
} */

#test {
    background-color: #fff;
    border-radius: 0;
}

.clientInformation {
    padding: 2% 2% 2% 2%;
    background-color: rgba(236, 236, 236, 0.81);
    border-radius: 3px;
    /* background-color: rgb(158, 174, 190); */
    /* color : white; */
    border: 1px solid #bdbdbd;
}

.clientInformation span {
    font-weight:bold ;
}

.clientInformation textarea {
    background-color: #fff;
}


.btn-row {
    display: flex;
    justify-content: flex-end;
    margin-right: 1%;
}

.createClientBtn button {
    border-radius: 7px;
    font-size: medium !important;
    color: black;
    background-color: #6585ca;
    padding: 5px 20px;
    border: none;
    transition: 0.4;
}

.createClientBtn button:hover{
    color: white;
    border-radius: 7px;
    background-color: #4ec9ee;
}