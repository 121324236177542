td,
th {
    border: 0.5px solid rgb(190, 190, 190);
    padding: 10px;
}

td {
    text-align: center;
    background-color: white;
}

tr:nth-child(even) {
    background-color: transparent;
}

th[scope="col"] {
    background-color: #4791db;
    width: 140px;
    color: #fff;
}

th[scope="row"] {
    background-color: white;
    border: 0.5px solid #e0e0e0;
}

td[scope="row"] {
    background-color: white;
    border: 0.5px solid #e0e0e0;
}

caption {
    padding: 10px;
    caption-side: bottom;
}

.clientPartialInformation {
    display: inline;
    float: none;
}

#a:hover~#b {
    opacity: 0.4;
}

#a:hover~.boxItem:after {
    background-color: #004478;
}

#a:hover~boxItem:after {
    background-color: #02375f;
}

.FusionButton {
    display: flex;
    flex-direction: row;
    text-align: center;
    background-color: #0c5791;
    color: #fff;
    z-index: 1;
    position:relative;
    border: none;
    border-radius: 20px;
    padding: 18px;
    font-weight: bold;
    margin: 0px 30px 0px 30px;
}

.FusionButton:hover {
    background-color: #004478;
}

.FusionButton:active {
    background-color: #02375f;
}

div.boxItem {
    display: inline-block;
    /* border: 1px solid black; */
    align-items: center;
    text-align: center;
    padding: 1em;
    position: relative
}

.boxItem:after {
    content: '';
    width: 14.6em;
    /* size of your margin */
    border-bottom: 3px solid #0c5791;
    position: absolute;
    top: 50%;

}

:after {
    left: 95.5%;
}


.boxItem:last-of-type:after {
    display: none;
}
