.EventDataGrid {
  border-radius: 5px;
  height: 300px;
  min-height: 60vh;
  width: 100%;
  text-align: 'center';
  background-color: white;
}

.eventListingTheme {
  background-color:#99ccff;
}

.clearResearch {
  text-align: center;
}

.clearResearch button
{
  color: black;
  background-color: #acacac !important;
  border-radius: 30px;
  text-align: center;
}

.clearResearch button:hover {
  color: white;
  background-color: #acacac !important;
}

.clearResearch button {
  color: black;
  background-color: #a3a3a3;
  padding: 2px 15px;
  border: none;
  transition: 0.4;
}

.clearResearch button:hover {
  background-color: #7c90d2;
}


.event-container {
  width: 70vw;
  min-height: 80vh;
  margin: 2vh auto;
  background: rgba(192, 185, 185, 0.37);
  box-shadow: -1px 1px 10px #c4c4c4;
  text-align: center;
  font-size: small;
  padding: 1vw;
}

.event-form h3 {
  font-family: PlayFair;
}

.event-form {
  text-align: center;
}

.event-form .MuiInput-formControl {
  margin: 20px 0;
  color:black !important;
}

#dateEvent,
#dateLimitInscription {
  padding-left: 5px;
  color: #7A6855;
  background-color: #FFFFFF;
}

@media (max-width: 768px) {
  .event-container {
    width: 95vw;
  }
}
