.scheduler {
    -webkit-box-sizing: border-box;
    /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;
    /* Firefox, other Gecko */
    box-sizing: border-box;
    /* Opera/IE 8+ */
    display: flex;
    flex-direction: column;
    min-width: 550px;
    margin-bottom: 15px;
}

.scheduler ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.bar {
    border-left: 3px solid black;
}

.scheduler .header {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 2;
}

.planning {
    display: flex;
}

.timeline ul {
    width: 100px;
    /* margin-top: 65px; */
    flex-grow: 1;
    border-right: 1px solid grey;
}

.timeline ul li {
    font-size: x-small;
    display: flex;
    align-items: flex-end;
    border-bottom: 1px solid #c8c8dc;
}

.planning-content {
    flex-grow: 1;
}

ul.events {
    display: flex;
}

ul.events li.group {
    margin-right: 10px;
    flex-grow: 1;
}

ul.events li.group .group-header {
    /* height: 50px; depends on [.timeline ul] margin-top value */
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

ul.events li.group .group-events {
    position: relative;
    border-top: 1px solid #c8c8dc;
    z-index: 1;
}

ul.events li.group .group-events .currentTimeBar {
    height: 1px;
    background-color: red;
    position: absolute;
    width: 100%;
}


ul.time-slots li.slot {
    position: relative;
}

.border-darker {
    border-bottom: 1px solid #c8c8dc;
}

.border-lighter {
    border-bottom: 1px solid lavender;
}

.event {
    position: absolute;
    width: 70%;
    background-color: aqua;
    border: 1px solid blue;
    font-size: xx-small;
    font-weight: 600;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}


/************
* TOOL TIP  *
*************/

/* Tooltip text */
.tooltiptext {
    visibility: hidden;
    width: 80px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
}

.info-tooltip:hover {
    background-color: #99ccff;
}

.info-tooltip:hover .tooltiptext {
    visibility: visible;
}

select {
    padding: 10px;
}