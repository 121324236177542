.voucherBtn {
    text-align: center;
}

input.btn-submit {
    color: #fff !important;
    font-weight: bold;
    background-color: #1976d2;
}

ul.voucher-items {
    font-size: x-small;
    max-height: 100px;
    width: 100%;
    border: 1px solid grey;
    margin-top: -20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: wrap;
}