.researchHelper {
    font-style: italic;
    font-size: xx-small;
}

.researchBox h3 {
    font-weight: bold;
}

.researchBox {
    display: "flex";
    justify-content: "center";
    width: 80%;
    margin-left:10%;
    padding: 2% 2% 2% 2%;
    text-align: center;
    background-color: #0e63d1a1;
    border: 2px solid black;
    height: 140px;
    color: white;
}

.researchGrid {
    height:100%;
    width:100%;
    border:'10px solid green';
}

@media screen and (max-width: 1500px) {
    .researchBox {
        display: "flex";
        justify-content: "center";
        margin-bottom: 2%;
        text-align: center;
    }
}

.researchColmun{
    background-color:rgba(25, 118, 210, 0.8);
    text-align: center;
    justify-content: center;
    color: white;

}
.tableLeftColumn{
    background-color:#d6e0f5;
    text-align: center;
    justify-content: center;
    color: black;
}