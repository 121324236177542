input {
  padding: 12px 15px 12px 15px !important;
  width: 100%;
  box-sizing: border-box;
  font-size: 16px !important;
  color: #000 !important;
  font-weight: 400
}

.card1 {
  width: 50%;
  padding: 40px 30px 10px 30px
}

.card2 {
  width: 50%;
  background-image: linear-gradient(130deg, #1B03A3, #00BFFF)
}

.msg-info {
  padding-left: 15px;
  margin-bottom: 30px
}

.btn-color {
  border-radius: 50px;
  color: #fff;
  background-image: linear-gradient(to right, #1B03A3, #00BFFF);
  padding: 15px;
  cursor: pointer;
  border: none !important;
  margin-top: 40px
}

.btn-color:hover {
  color: #fff;
  background-image: linear-gradient(to right, #00BFFF, #1B03A3)
}

@media screen and (max-width: 992px) {
  .card1 {
      width: 100%;
      padding: 40px 30px 10px 30px
  }

  .card2 {
      width: 100%
  }

  .right {
      margin-top: 100px !important;
      margin-bottom: 100px !important
  }
}

@media screen and (max-width: 768px) {
  .container {
      padding: 10px !important
  }

  .card2 {
      padding: 50px
  }

  .right {
      margin-top: 50px !important;
      margin-bottom: 50px !important
  }
}
