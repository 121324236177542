.siteInfo{
    background-color: #72caed;
    text-align: center;
    color: #111010;
}

.site-grid-container {
    text-align: center;
}

.site-data-grid {
    height: 400px;
    width : 100% !important;
    display: inline-flex;
}